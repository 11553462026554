import './style.css'
import * as THREE from 'three'
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
//import * as dat from 'dat.gui'
//import Stats from 'stats.js'
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {HalfFloatType} from "three";
import shad3frag from './shaders/shader3/frag.glsl'
import shad3vert from './shaders/shader3/vert.glsl'
import wavefrag from './shaders/wavefrag.glsl'
import { EffectComposer, RenderPass, EffectPass, GodRaysEffect, SelectiveBloomEffect } from "postprocessing"
import {Text, preloadFont} from 'troika-three-text'
import { createDerivedMaterial} from 'troika-three-utils'




//#region base stuff

//const KenokyFont = '/fonts/KenokyLight.ttf'
const Jost = '/fonts/Aveny.woff'
const jost200 = Jost
const jost400 = jost200 //'/fonts/jost-v9-latin-regular.woff' //'https://fonts.gstatic.com/s/jost/v9/92zPtBhPNqw79Ij1E865zBUv7myjJTVBNI4un_HKOEo.woff'


/* preloadFont(
  {
    font: KenokyFont,
    characters: 'abcdefghijklmnopqrstuvwxyz'
  },
  () => {
    
  }
) */
preloadFont(
  {
    font: Jost,
    characters: 'abcdefghijklmnopqrstuvwxyz'
  },
  () => {
    
  }
)
/**
 * Stats
 */
 /* const stats = new Stats()
 stats.showPanel(0)
 document.body.appendChild(stats.dom) */
 
// Canvas
const canvas = document.querySelector('canvas.webgl')


// Scene
const scene = new THREE.Scene()

// debug
/* const gui = new dat.GUI()
gui.close()
gui.hide() */

// manager
 const manager = new THREE.LoadingManager();

// Texture loader
const textureLoader = new THREE.TextureLoader(manager)

//#endregion

//#region // declarations - colors - second manager and loader loading two textures
let whitecolor = new THREE.Color('#ffffff');
let blackcolor = new THREE.Color('#000000');
let blackcolorPaintings = new THREE.Color('#000000');
let graycolor = new THREE.Color('#0d0d0d'); //#413e3e #030303
let whitecolor2 = new THREE.Color('#ffffff');
let windowrightMesh;
let windowleftMesh;
let room;
const introTextChars = ".intro-text span";
const title = ".title span";
const scroll = ".scroll"
const scrollcontainer = ".scrollcontainer"
const tr = "#tr";
const verselogo = ".verselogo";
const vlogo = ".vlogo";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const secondmanager = new THREE.LoadingManager()
const secondtextureloader = new THREE.TextureLoader(secondmanager)

const bedroompaintingtex = secondtextureloader.load('/textures/bedroom_cropped.jpg')
bedroompaintingtex.encoding = THREE.sRGBEncoding
const texture1 = bedroompaintingtex
const logoSmalltex = secondtextureloader.load('/logo/logo-small.jpg')
logoSmalltex.encoding = THREE.sRGBEncoding

//group gallery
const galleryGroup = new THREE.Group()
scene.add(galleryGroup)
galleryGroup.position.set(0, -0.6, 0)

//#endregion

//#region shaders
//shader wave
let progressWave = {value: 1.0}
const wavematerial = new THREE.ShaderMaterial({
  extensions: {
    derivatives: "#extension GL_OES_standard_derivatives : enable"
  },
  /* side: THREE.DoubleSide, */
  uniforms: {
    time: { type: "f", value: 0 },
    progress: { type: "f", value: progressWave.value },
    border: { type: "f", value: 0 },
    intensity: { type: "f", value: 0 },
    scaleX: { type: "f", value: 40 },
    scaleY: { type: "f", value: 40 },
    transition: { type: "f", value: 40 },
    swipe: { type: "f", value: 0 },
    width: { type: "f", value: 0.35 },
    radius: { type: "f", value: 0.9 },
    texture1: { type: "f", value: texture1 },
    //texture2: { type: "f", value: this.textures[1] },
    displacement: { type: "f", value: new THREE.TextureLoader().load('textures/disp1.jpg') },
    resolution: { type: "v4", value: new THREE.Vector4() },
  },
  // wireframe: true,
  vertexShader: `varying vec2 vUv;void main() {vUv = uv;gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );}`,
  fragmentShader: wavefrag
});
wavematerial.uniforms.resolution.value.x = 2.2;
wavematerial.uniforms.resolution.value.y = 1.65;
wavematerial.uniforms.resolution.value.z = 1;
wavematerial.uniforms.resolution.value.w = 1;
wavematerial.transparent = true
//wavematerial.alphaTest = 0.9
wavematerial.depthWrite = false
//wavematerial.needsUpdate = true

// shader3
let alphaValue1 = {value: 1.0}
let uniformsshad1 = {
  "time": { value: 1.0 },
  "alpha": { value: alphaValue1.value }
 };

const shad3 = new THREE.ShaderMaterial({  
    uniforms: uniformsshad1,
    side:THREE.FrontSide,
    vertexShader: shad3vert,
    fragmentShader: shad3frag
});
// godray bat logo
let circleGeo = new THREE.PlaneBufferGeometry(1, 1);
let circleMat = new THREE.MeshBasicMaterial({color: 0xffccaa});
//circleMat.transparent = true
//circleMat.opacity = 1
let circle = new THREE.Mesh(circleGeo, shad3);
circle.position.set(-0.7 ,2 , -0.2);
circle.scale.set(1.8, 1.8);

// animation godray
gsap.to (circle.position, {x: -0.2,/*  ease: 'power2.inOut', */ duration: 7, yoyo: true, repeat:-1})

 // shad4
 let alphaValue = {value: 1.0}
 let uniformsshad5 = {"time": { value: 1.0 }, "alpha": { value: alphaValue.value }};
// shader 4 = clone shader
let shad4 = shad3.clone()
shad4.uniforms = uniformsshad5
shad4.wireframe = true
shad4.transparent = true

// shader text the bedroom 
const baseMaterial = new THREE.MeshBasicMaterial()
const customMaterial = createDerivedMaterial(
  baseMaterial,
  {
    uniforms: uniformsshad1,
    vertexDefs: `
      varying vec2 vUv;
  `,
    vertexMainIntro: `
      vUv = uv;
      vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    `,
    vertexMainOutro: `
      gl_Position = projectionMatrix * mvPosition;
    `,
    fragmentDefs: `
      uniform float time;
      uniform float alpha;
			varying vec2 vUv;
  `,
    fragmentMainIntro: `
      vec2 position = - 1.0 + 2.0 * vUv;

      float red = abs( sin( position.x * position.y + time / /** 5 */4.0 ) );
      float green = abs( sin( position.x * position.y + time / 2.0 ) );
      float blue = abs( sin( position.x * position.y + time / 3.0 ) );
    `,
    fragmentMainOutro: `
      gl_FragColor = vec4( red, green, blue, alpha );
    `

  }
)

// shader text dilemma 
const basemetaMaterial = new THREE.MeshBasicMaterial()
const custometamMaterial = createDerivedMaterial(
  basemetaMaterial,
  {
    uniforms: {
      "color1" : {
        type : "c",
        value : new THREE.Color(0.701, 0, 0.039)
      },
      "color2" : {
        type : "c",
        value : new THREE.Color(0, 0, 1)
      },
    },
    vertexDefs: `
      varying vec2 vUv;
  `,
    vertexMainIntro: `
      vUv = uv;
      vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    `,
    vertexMainOutro: `
      gl_Position = projectionMatrix * mvPosition;
    `,
    fragmentDefs: `
    uniform vec3 color1;
    uniform vec3 color2;
    varying vec2 vUv;
  `,
    fragmentMainOutro: `
    gl_FragColor = vec4(mix(color1, color2, sin(vUv.x)),1.0);
    `
  }
)
// shader text aburtuinty
const baseOppouMaterial = new THREE.MeshBasicMaterial()
const customOppouMaterial = createDerivedMaterial(
  baseOppouMaterial,
  {
    uniforms: {
      "color1" : {
        type : "c",
        value : new THREE.Color(0, 0.541, 0.086)
      },
      "color2" : {
        type : "c",
        value : new THREE.Color(0, 0, 1)
      },
    },
    vertexDefs: `
      varying vec2 vUv;
  `,
    vertexMainIntro: `
      vUv = uv;
      vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    `,
    vertexMainOutro: `
      gl_Position = projectionMatrix * mvPosition;
    `,
    fragmentDefs: `
    uniform vec3 color1;
    uniform vec3 color2;
    varying vec2 vUv;
  `,
    fragmentMainOutro: `
    gl_FragColor = vec4(mix(color1, color2, sin(vUv.x)),1.0);
    `
  }
)
const basetext3Material = new THREE.MeshBasicMaterial()
const textgradmat4 = createDerivedMaterial(
  basetext3Material,
  {
    uniforms: {
      "color1" : {
        type : "c",
        value : new THREE.Color(0.929, 0.458, 0.047)
      },
      "color2" : {
        type : "c",
        value : new THREE.Color(0.619, 0, 0.023)
      },
    },
    vertexDefs: `
      varying vec2 vUv;
  `,
    vertexMainIntro: `
      vUv = uv;
      vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    `,
    vertexMainOutro: `
      gl_Position = projectionMatrix * mvPosition;
    `,
    fragmentDefs: `
    uniform vec3 color1;
    uniform vec3 color2;
    varying vec2 vUv;
  `,
    fragmentMainOutro: `
    gl_FragColor = vec4(mix(color1, color2, cos(vUv.x)),1.0);
    `
  }
)
const basetext4Material = new THREE.MeshBasicMaterial()
const textgradmat3 = createDerivedMaterial(
  basetext4Material,
  {
    uniforms: {
      "color1" : {
        type : "c",
        value : new THREE.Color(0.023, 0.792, 0.996)
      },
      "color2" : {
        type : "c",
        value : new THREE.Color(0.435, 0, 0.619)
      },
    },
    vertexDefs: `
      varying vec2 vUv;
  `,
    vertexMainIntro: `
      vUv = uv;
      vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    `,
    vertexMainOutro: `
      gl_Position = projectionMatrix * mvPosition;
    `,
    fragmentDefs: `
    uniform vec3 color1;
    uniform vec3 color2;
    varying vec2 vUv;
  `,
    fragmentMainOutro: `
    gl_FragColor = vec4(mix(color1, color2, cos(vUv.x)),1.0);
    `
  }
)

//red green progress text shader:
import testVertexShader from './shaders/test/vertex.glsl'
import testFragmentShader from './shaders/test/fragment.glsl'

const brogreessRedColor1 = new THREE.Color(0.392, 0.007, 0.047) //dark red
const brogreessGreenColor1 = new THREE.Color(0.007, 0.392, 0.031) //dark green

 /**
 * shader test  https://codepen.io/ilithya/pen/XWXvvmj
 */
  const vertexShader = testVertexShader;
  const fragmentShader = testFragmentShader;
  const uniSour2 = { type: "v3", value: new THREE.Vector3(0, 0, 1) }; // red
  const uniforms3 = {
     u_c1: { type: "v3", value: brogreessRedColor1 },
     u_c2: uniSour2,
     u_time: { type: "f", value: 1.0 },
  };
  const redgreenshad = new THREE.ShaderMaterial({
     uniforms: uniforms3,
     side:THREE.DoubleSide,
     fragmentShader,
     vertexShader
  });

//#endregion

//#region load main room

let gltfLoader = new GLTFLoader(manager)
gltfLoader.load(
  '/models/room2/room.gltf', /* /vann/vanbedroom.gltf */
  (gltf) =>
  {
    room = gltf.scene

    windowrightMesh = gltf.scene.children.find(child => child.name === 'Window_Right')
    windowleftMesh = gltf.scene.children.find(child => child.name === 'Window_Left')
    const restofroom = gltf.scene.children.find(child => child.name === 'Room')
    const roomMesh = restofroom.children[0]
    roomMesh.traverse(child => {
      if (child.isMesh){
        child.material.color = blackcolor;
        //child.layers.enable(11);
      }
    });

    windowleftMesh.rotation.y = 0.5
    windowrightMesh.rotation.y = -0.9
    const paintings = roomMesh.children.find(child => child.name === 'mesh_0_2')
    //paintings.material.transparent = true
    paintings.material.color = blackcolorPaintings
    //paintings.visible = false
    const wallss = roomMesh.children.find(child => child.name === 'mesh_0')
    //wallss.material.transparent = true
    wallss.material.color = blackcolorPaintings
      
    /* const elements = roomMesh.children.find(child => child.name === 'mesh_0_1')
    elements.material.transparent = true
    //elements.material.color = whitecolor2
    //elements.visible = false */

    const tableobjects = gltf.scene.children.find(child => child.name === 'Table_Objects')
    const tableobjectsMesh = tableobjects.children[0]
    tableobjectsMesh.layers.enable(11);
    tableobjectsMesh.material.color = blackcolor
      
    const hat = gltf.scene.children.find(child => child.name === 'hat')
    const hatMesh = hat.children[0]
    //hatMesh.layers.enable(11);
    hatMesh.material.color = blackcolor
    //CLONE hat and table objects
    const newtableobjects = tableobjectsMesh.clone()
    newtableobjects.position.set(-0.001, 0, 0)
    newtableobjects.layers.enable(11);
    newtableobjects.material = shad4
    scene.add(newtableobjects)
    const newHat = hatMesh.clone()
    newHat.position.set(-0.001, 0, 0)
    hatMesh.layers.enable(1);
    newHat.material = shad4
    scene.add(newHat)
    scene.add(room)
    //console.log(elements)

  }
)
//#endregion

//#region ///text
let myText;
  let vincettext;
  // Create text:
  myText = new Text()
  galleryGroup.add(myText)
  // Set properties to configure:
  myText.text = 'THE BEDROOM'
  myText.font = jost400
  myText.fontSize = 0.2
  myText.letterSpacing = 0.2
  //myText.selectable = true
  //myText.depthOffset = 8
  myText.color = 0xff6666
  myText.material = customMaterial
  myText.position.set(-1.22, 0.74, 6.03)
  myText.scale.z = 10

  // Create text:
  vincettext = new Text()
  galleryGroup.add(vincettext)
  vincettext.text = `Vincent van Gogh, October 1888
oil on canvas, 72.4 cm x 91.3 cm`
  vincettext.font = jost200
  vincettext.fontSize = 0.12
  vincettext.color = whitecolor
  vincettext.position.set(-1.2, 0.517, 6.03)
  vincettext.sync()
  myText.sync()

  //text we create
  const wecreate = new Text()
  wecreate.text = `We create interactive, immersive and unforeseen experiences that adds a new dimension to the way brands communicate with the world.`/* .toUpperCase(); */
  wecreate.font = jost200
  wecreate.fontSize = 0.17
  wecreate.maxWidth = 1.5
  wecreate.color = whitecolor
  wecreate.fillOpacity = 0
  wecreate.strokeWidth = 0.001
  wecreate.strokeOpacity = 0
  wecreate.strokeColor = whitecolor
  wecreate.position.set(1.5, 2.7, 0.33)
  wecreate.rotation.set(0, 4.68, 0)
  scene.add(wecreate)
  wecreate.sync()

  // textprogress
  const redlinegeom = circleGeo.clone()
  redlinegeom.scale(0.2, 0.02, 1)
  redlinegeom.translate(0.1, 0 , 0)
  const redlinemesh = new THREE.Mesh ( redlinegeom, redgreenshad )
  redlinemesh.scale.set( 0, 1, 1)
  redlinemesh.position.set(-2.65, 2, 12.62)
  redlinemesh.rotation.set(-0.01, -1.57, 0)
  //redlinemesh.layers.enable(11);
  scene.add(redlinemesh)

  //create text meta dilemma
  const metaversedilemma= new Text()
  scene.add(metaversedilemma)
  metaversedilemma.text = `The metaverse dilemma`
  metaversedilemma.font = jost400
  metaversedilemma.letterSpacing = 0.01
  metaversedilemma.fontSize = 0.115
  metaversedilemma.maxWidth = 1.7
  metaversedilemma.material = custometamMaterial
  //metaversedilemma.color = whitecolor
  metaversedilemma.fillOpacity = 1
  //metaversedilemma.strokeWidth = 0.001
  metaversedilemma.strokeOpacity = 0
  //metaversedilemma.strokeColor = whitecolor
  metaversedilemma.position.set(-2.657, 1.9, 12.62)
  metaversedilemma.rotation.set(0, 1.571, 0)
  metaversedilemma.material.transparent = true
  metaversedilemma.material.opacity = 1
  metaversedilemma.sync()
  
  const opportunity = metaversedilemma.clone()
  opportunity.text = `The metaverse opportunity`
  opportunity.material = customOppouMaterial
  metaversedilemma.position.set(-2.656, 1.9, 12.62)
  opportunity.fillOpacity = 0
  scene.add(opportunity)
  opportunity.sync()

  const grayText = new THREE.Color('#808080')//new THREE.Color('#ffffff')
  
  //create meta dilemma content
  const contentedilemma= new Text()
  scene.add(contentedilemma)
  contentedilemma.text = `Most websites and marketing campaigns have the same format, visuals and same old “branding” techniques, creating this “messy middle.”

According to a recent Everest study, 73% of companies didn’t provide any business value from their digital initiatives. That’s why it’s integral that companies act now to deliver digital marketing and brand promotion that works.`
  contentedilemma.font = jost200
  //contentedilemma.letterSpacing = 0.01
  contentedilemma.lineHeight = 1.3
  contentedilemma.fontSize = 0.08
  contentedilemma.maxWidth = 1.15
  contentedilemma.color = grayText
  //contentedilemma.fillOpacity = 1
  contentedilemma.position.set(-2.657, 1.7, 12.62)
  contentedilemma.rotation.set(0, 1.571, 0)
  contentedilemma.material.transparent = true
  contentedilemma.material.opacity = 1
  contentedilemma.sync()
  
  const contentedilemma2 = contentedilemma.clone()
  contentedilemma2.text = `Social media has become this melting pot of frustrations, but with new technology that’s more immersive; it allow for more visual and human interaction, allow for more empathy. This should be a natural transition, because humans aren't thinking machines, but feeling machines that think.`
  contentedilemma2.position.set(-2.656, 1.7, 12.62)
  contentedilemma2.rotation.set(0, 1.571, 0)
  //contentedilemma2.material = new THREE.MeshBasicMaterial
  //contentedilemma2.material.transparent = true
  //contentedilemma2.material.opacity = 0
  //contentedilemma2.sync()
  //scene.add(contentedilemma2)

  const text3 = contentedilemma.clone()
  text3.material = new THREE.MeshBasicMaterial
  text3.material.transparent = true
  text3.material.opacity = 0
  text3.position.set(-2.655, 1.7, 12.62)
  text3.text = `Keep your brand identity fresh, contemporary, and positive. These days, customers expect personalized and meaningful brand interactions.

Stand out from the crowd with a new and improved digital presence. Keep ahead of the curve and create a lasting brand identity with the endless possibilities as 2D becomes 3D.`
  text3.sync()
  scene.add(text3)

  const text4 = contentedilemma.clone()
  //text4.material = new THREE.MeshBasicMaterial
  //text4.material.transparent = true
  //text4.material.opacity = 0
  text4.position.set(-2.654, 1.7, 12.62)
  text4.text = `Customers today are savvy and expectant. They anticipate personalized service and interactive visuals. And they desire greater meaning from their brand interactions.

It’s about creating brand advocates and inviting them to feel a part of something greater. It’s about inspiring people, involving them, and rewarding them. And it’s about making them feel good every single time they experience your brand to drive positive and long-lasting perception.`
  //text4.sync()
  //scene.add(text4)

  const imaginationText = text4.clone()
  imaginationText.text = `this is the dimension
                       of imagination.`
  imaginationText.lineHeight = 1.8
  imaginationText.letterSpacing = 0.06
  imaginationText.maxWidth = 1.4
  imaginationText.fontSize = 0.1
  const imaginationTextMat = createDerivedMaterial(new THREE.MeshBasicMaterial(), {
    timeUniform: 'elapsed',
    vertexTransform: `
        // NOTE: gl_InstanceID only works in WebGL2, and also appears to fail in ANGLE
        // implementations when used within functions other than main(). 
        // We should probably add a 'charIndex' attribute to the text geometry for this purpose.
        
        float charIndex = float(gl_InstanceID); //position.x;
        float loopProgress = mod(elapsed - (charIndex * 200.0), 2100.0) / 2100.0;
        float verticalness = clamp(0.0, 1.0, min(loopProgress / 0.6, (1.0 - loopProgress) / 0.2));
        float angle = (1.0 - verticalness) * PI / 3.5;
        position.z = position.y * -sin(angle);
        position.y *= cos(angle);
      `
  })
  imaginationText.material = imaginationTextMat
  imaginationText.sync()
  scene.add(imaginationText)
  imaginationText.position.set(-2.652, 1.26, 17.54)

  //create second panal: servises
  const webexp= new Text()
  scene.add(webexp)
  webexp.text = `Web Experiences`
  webexp.font = jost400
  webexp.letterSpacing = 0.01
  webexp.fontSize = 0.13
  webexp.maxWidth = 1.7
  webexp.material = textgradmat3
  webexp.position.set(-2.645, 1.95, 14.47)
  webexp.rotation.set(0, 1.571, 0)
  webexp.sync()
  
  const text5 = contentedilemma.clone()
  text5.material = new THREE.MeshBasicMaterial()
  text5.position.set(-2.646, 1.8, 14.47)
  text5.rotation.set(0, 1.57, 0)
  text5.text = `Potential customers always check out a company’s websites before getting in touch. This is why a cutting-edge website is essential for making a positive impression and building trust.`
  text5.sync()
  scene.add(text5)

  const text6 = contentedilemma.clone()
  text6.material = new THREE.MeshBasicMaterial()
  text6.position.set(-2.646, 0.95, 14.47)
  text6.rotation.set(0, 1.57, 0)
  text6.text = `Take your social platforms to the next level with custom branded Instagram filters. Unlock your company’s full potential through the innovative power of augmented reality, connect with your customers and let them be your brand ambassadors.`
  text6.sync()
  scene.add(text6)

  const servisestext = webexp.clone()
  scene.add(servisestext)
  servisestext.text = `SERVICES`
  servisestext.letterSpacing = 0.3
  servisestext.font = jost200
  servisestext.fontSize = 0.05
  servisestext.material = new THREE.MeshBasicMaterial()
  servisestext.position.set(-2.645, 2.05, 14.47)
  servisestext.sync()

  const socialexp = webexp.clone()
  scene.add(socialexp)
  socialexp.text = `Social Experiences`
  socialexp.material = textgradmat4
  socialexp.position.set(-2.645, 1.1, 14.47)
  socialexp.sync()
  //#endregion

//#region ///////MAIN scene loaded
manager.onLoad = function ( ) {
	//console.log( 'Loading complete!');

  //#region load musuem
  const gltfLoaderr = new GLTFLoader(secondmanager)
  gltfLoaderr.load(
   '/models/musuem/musuem.glb',
   (gltf) =>
   {     
     const newplan = circleGeo.clone()
     const newmeshh = new THREE.Mesh( newplan, wavematerial)
     galleryGroup.add(newmeshh)
     newmeshh.position.set(-0.07, 1.75, 6.19)
     newmeshh.scale.set(2.2, 1.65)

     const logosmallplane = circleGeo.clone()
     logosmallplane.scale(1.6, 1.6, 1)
     const logosmallmat = new THREE.MeshBasicMaterial({map: logoSmalltex})
     logosmallmat.transparent = true
     const logoSmall = new THREE.Mesh( logosmallplane, logosmallmat)
     logoSmall.position.set(2.33, 1.35, 19.8)
     logoSmall.scale.set(1.0, logoSmalltex.image.height / logoSmalltex.image.width, 1.0);
     galleryGroup.add(logoSmall)
     galleryGroup.add(gltf.scene)
  })
 //#endregion
 //#region add circle - scroll to appear - cover plane - camera rotate order - 
 scene.add(circle);
 document.querySelector(".scroll").style.display = 'block';
 const coverPlanegeo = circleGeo.clone()
 const coverPlanemat = new THREE.MeshBasicMaterial({color: new THREE.Color(0.001, 0.001, 0.001)})
 const coverPlane = new THREE.Mesh(coverPlanegeo, coverPlanemat)
 coverPlane.position.set(-2.7, 1.2, 7)
 coverPlane.scale.set(25, 5, 1)
 scene.add(coverPlane)
 coverPlane.rotation.set(-0.01, -1.57, 0)
 coverPlane.visible = false

 camera.rotation.order = 'YXZ';
 //#endregion

  //#region city
  const cityGroup = new THREE.Group()
  cityGroup.position.set(-15, -0.6, -10);
  cityGroup.rotation.set(0, 0, 0);

let citymanmat;
//let blacklightmesh;
let backlightmat;
let smokemat;
let blacklightgodRays;
let citymat;
let sparklemat;
/////city 

scene.add(cityGroup)

//cityGroup.position.set(-70, -0.6, 75);
//cityGroup.rotation.set(0, 2, 0);
/* gui.add(cityGroup.rotation, 'x').min(-30).max(10).step(0.001)
 gui.add(cityGroup.rotation, 'y').min(-30).max(10).step(0.001)
 gui.add(cityGroup.rotation, 'z').min(-30).max(10).step(0.001) */
let citymangeom = circleGeo.clone()
citymangeom.scale(20, 20, 1)
var citymanmesh;
var citymantex = secondtextureloader.load('/textures/cityman/cityman.png', (citymantex) => {
  //citymantex.needsUpdate = true;
  citymantex.encoding = THREE.sRGBEncoding;
  citymanmesh.scale.set(1.0, citymantex.image.height / citymantex.image.width, 1.0);
});
//citymantex.needsUpdate = true;
citymanmat = new THREE.MeshBasicMaterial({ map: citymantex });
citymanmat.color = new THREE.Color('#000000');
citymanmat.transparent = true
citymanmat.opacity = 1
citymanmat.side = THREE.FrontSide
//citymanmat.depthWrite = false
//citymanmat.alphaTest = 0.1
citymanmesh = new THREE.Mesh(citymangeom, citymanmat);
//citymanmesh.scale.set(1.0, citymantex.image.height / citymantex.image.width, 1.0);
cityGroup.add(citymanmesh);  

//city
let citygeom = circleGeo.clone()
citygeom.scale(21, 21, 1)

var citymesh;
var citytex = secondtextureloader.load('/textures/cityman/city.png', (citytex) => {
  //citytex.needsUpdate = true;
  citytex.encoding = THREE.sRGBEncoding;
  citymesh.scale.set(1.0, citytex.image.height / citytex.image.width, 1.0);
});
citymat = new THREE.MeshBasicMaterial({ map: citytex });
citymat.color = new THREE.Color('#000000');
citymat.transparent = true
citymat.opacity = 1
citymat.side = THREE.FrontSide
//citymanmat.depthWrite = false
//citymanmat.alphaTest = 0.1
citymesh = new THREE.Mesh(citygeom, citymat);
citymesh.position.set(0, 0, -3);
cityGroup.add(citymesh);

//blacklight
let backlightgeom = circleGeo.clone()
backlightgeom.scale(20, 20, 1)
//blacklighttex.needsUpdate = true;
var blacklightmesh;
var blacklighttex = secondtextureloader.load('/textures/cityman/4Asset1.png', (blacklighttex) => {
  //blacklighttex.needsUpdate = true;
  blacklighttex.encoding = THREE.sRGBEncoding;
  blacklightmesh.scale.set(1.0, blacklighttex.image.height / blacklighttex.image.width, 1.0);
});
backlightmat = new THREE.MeshBasicMaterial({ map: blacklighttex });
backlightmat.transparent = true
backlightmat.opacity = 0.1
backlightmat.side = THREE.FrontSide
blacklightmesh = new THREE.Mesh(backlightgeom, backlightmat);
//blacklightmesh.scale.set(1.0, blacklighttex.image.height / blacklighttex.image.width, 1.0);
blacklightmesh.position.set(0, 0, -1);
//blacklightmesh.rotation.set(0, 2, 0);
//blacklightmesh.layers.enable(11);
cityGroup.add(blacklightmesh); 

//smoke
 let smokegeom = circleGeo.clone()
 smokegeom.scale(2.5, 2.5, 1)
//blacklighttex.needsUpdate = true;
var smokemesh;
var smoketex = secondtextureloader.load('/textures/cityman/smoke.png', (smoketex) => {
  //blacklighttex.needsUpdate = true;
  smoketex.encoding = THREE.sRGBEncoding;
  smokemesh.scale.set(1.0, smoketex.image.height / smoketex.image.width, 1.0);
});
smokemat = new THREE.MeshBasicMaterial({ map: smoketex });
//smokemat.depthWrite = false
//smokemat.alphaTest = 0.0001
smokemat.transparent = true
smokemat.opacity = 0
smokemat.side = THREE.FrontSide

smokemesh = new THREE.Mesh(smokegeom, smokemat);
smokemesh.position.set(0.8, 2, -0.3);
//blacklightmesh.rotation.set(0, 2, 0);
//blacklightmesh.layers.enable(11);
cityGroup.add(smokemesh);

//small sparkle
let sparklegeom = circleGeo.clone()
sparklegeom.scale(2.5, 2.5, 1)
//blacklighttex.needsUpdate = true;
var sparklemesh;
var sparkletex = secondtextureloader.load('/textures/cityman/smallsparkle.png', (sparkletex) => {
  //blacklighttex.needsUpdate = true;
  smoketex.encoding = THREE.sRGBEncoding;
  sparklemesh.scale.set(1.0, sparkletex.image.height / sparkletex.image.width, 1.0);
});
sparklemat = new THREE.MeshBasicMaterial({ map: sparkletex });
sparklemat.depthWrite = false
sparklemat.alphaTest = 0.0001
sparklemat.transparent = true
sparklemat.opacity = 0
sparklemat.side = THREE.FrontSide
sparklemesh = new THREE.Mesh(sparklegeom, sparklemat);
sparklemesh.position.set(0.55, .4, 0.1);
//blacklightmesh.rotation.set(0, 2, 0);
//sparklemesh.layers.enable(11);
cityGroup.add(sparklemesh);
//#endregion

//#region  toggle up and down animations
  var vtl = gsap.timeline({repeat:-1, delay: 6, repeatDelay: 3});
  vtl
    .to (vlogo, {y: 30, duration: 1})
    .to (vlogo, {y: 0, duration: 1}, ">");
  //vtl.pause()

  let scrollToValue = window.scrollY;
  const scrollcontainerelm = document.querySelector(".scrollcontainer");
  const scrollBottom = (scrollcontainerelm.offsetHeight - 1000)
  //console.log(scrollBottom)

  window.onscroll = function() {
    //console.log(scrollToValue)
    scrollToValue = window.scrollY
    if(scrollToValue >= scrollBottom){
      vtl.restart()
      vtl.pause()
      //ctl.kill();
      //ctl.reverse(-0.5)

    } else if(scrollToValue >= scrollBottom - 1000){
      vtl.play()
      ctl.reverse(-0.5)
      //console.log("scrollBottom - 1000")

    } else{
      vtl.play()
    }
  };

  function increase(){
    if(scrollToValue < (scrollBottom + 500)){
      scrollToValue += 2000;
    }
  }
  /* function decresase(){
    if(scrollToValue > 0){
      scrollToValue -= 800;
    }
  } */

  const toplogoclick = document.querySelector(".artlogo");
  toplogoclick.onclick = function(){
    //decresase()
    gsap.to (window, {duration: 2, scrollTo: 0, ease: 'Power2.easeOut'})
  };
  const vlogoclick = document.querySelector(".vlogo");
  vlogoclick.onclick = function(){
    increase()
    gsap.to (window, {duration: 1, scrollTo: scrollToValue})
  };
  const scrollclick = document.querySelector(".scroll");
  scrollclick.onclick = function(){
    increase()
    gsap.to (window, {duration: 1, scrollTo: scrollToValue})
  };

  var ctl = gsap.timeline({paused: true});
  ctl
  //.set('.scrollcontainer', {touchAction: "none !important"})
  //.set('.contact-container', {touchAction: "none !important"})
  .set('body', {overflowY: "hidden"})
  .fromTo(".contactus", {autoAlpha: 1}, {autoAlpha: 0, duration: 0.5, immediateRender: false}, "<")
  .to(".card", {autoAlpha: 1, duration: 0.5, ease: "none"}, "<")
  .to(".cardd", {autoAlpha: 1, duration: 0.5, ease: "none"}, "<")
  .fromTo(".contact-container", {autoAlpha: 0}, {autoAlpha: 1, duration: 0.5, immediateRender: false}, "<");
  //.set('.scrollcontainer', {overflowY: "clip"}, "<");


const contactclick = document.querySelector(".contactus");
contactclick.onclick = function(){
  ctl.play()
  //document.body.ontouchmove = (e) => { e.preventDefault(); return false; };
};
const closecontactclick = document.querySelector(".closecontact");
closecontactclick.onclick = function(){
  ctl.reverse()

};
  //#endregion

  //#region //main animation timeline
  ScrollTrigger.matchMedia({
	
    // mobile portrait
    "(max-device-width: 476px) and (orientation: portrait) and (hover: none)": function() {
      let tl = gsap.timeline({
        scrollTrigger: {
          scrub: 1,
          trigger: scrollcontainer,
          start: 0,
          end: scrollcontainerelm.offsetHeight,
          //scroller: "body"
          //onUpdate: /* self => console.log("progress:", self.progress)// */trigger => {if (trigger.progress >= 0.8 ) {ctl2.play(); console.log(trigger.progress);}}
          //markers: true,
        },
        defaults: {
          ease: "none"
        }
      });
      tl
        .to(windowleftMesh.rotation, {y: -2.2, ease: 'none', duration: 7.2})
        .to(windowrightMesh.rotation, {y: 2, ease: 'none', duration: 8}, "<")
        .to(blackcolor, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'Power2.easeIn', duration: 5}, "<")
        .to(blackcolorPaintings, {r: whitecolor2.r, g: whitecolor2.g, b: whitecolor2.b, ease: 'Power2.easeIn', duration: 5}, "<")
    
        .to(alphaValue, {value: 0.0, ease: 'none', duration: 6}, "<")
        .to(vlogo, {top: "90%", duration: 128}, '<')///
        .to(verselogo, {opacity: 0, x: 70, duration: 4}, '<')    
        .to(tr, {opacity: 0, x: 500, duration: 4}, '<1')
    
        .to(title, {opacity: 0, x: -100, stagger: 0.3, duration: 4}, '<')
        .to(".scrollbutton", {bottom: -120, opacity: 0, duration: 3}, "<")
        .to(camera.position, {x: -0.57, y: 1.665, z: 1.665, duration: 5}, ">")
        .to(camera.rotation, {x: -0.503, y: 0.581, z: 0.364, duration: 5}, "<")
    
        .to(".vincent span", {duration: 3, x: 0, filter: "blur(0px)", opacity: 1, stagger: 0.6}, ">-1")
        .to(".vincent span", {duration: 2, y: 100, filter: "blur(8px)", opacity: 0, stagger: 0.3}, ">1")
    
        .to(camera.position, {x: 0.147, y: 4.699, z: 2.532, duration: 4}, ">-0.5")
        .to(camera.rotation, {x: -1.153, y: 0, z: 0, duration: 4}, "<")
    
        .to(introTextChars, {y: 0, opacity: 1, stagger: 0.3, duration: 2}, ">")
        .to(introTextChars, {x:20, stagger: 0.6, opacity: 0, duration: 2}, ">0.5")
       
        //close windows
        .to(windowleftMesh.rotation, {y: 0.5, ease: 'none', duration: 6.2}, ">-2")
        .to(windowrightMesh.rotation, {y: -0.9, ease: 'none', duration: 7}, "<")
    
        .to(camera.position, {x: -3, y: 1.35, z: .8, duration: 7}, "<2")
        .to(camera.rotation, {x: 0, y: -1.6, z: 0, duration: 7}, "<")
    
        .to(blackcolor, {r: graycolor.r, g: graycolor.g, b: graycolor.b, ease: 'none', duration: 6}, "<1")
        .to(blackcolorPaintings, {r: blackcolorPaintings.r, g: blackcolorPaintings.g, b: blackcolorPaintings.b, ease: 'none', duration: 6}, "<")
        .to(alphaValue, {value: 1, ease: 'none', duration: 5}, "<2")
    
        //text we create
        .to(wecreate, {strokeOpacity: 1, duration: 3}, "<3.7")
        .to(wecreate, {fillOpacity: 1, duration: 2}, ">-2")
    
        .to(wecreate, {fillOpacity: 0, duration: 2}, ">")
        .to(wecreate, {strokeOpacity: 0, duration: 3}, ">-2")
    
        //open windows
        .to(windowleftMesh.rotation, {y: -2.2, ease: 'none', duration: 6.2}, ">-2")
        .to(windowrightMesh.rotation, {y: 2, ease: 'none', duration: 7}, "<")
        .to(blackcolor, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 6}, "<2")
        .to(blackcolorPaintings, {r: whitecolor2.r, g: whitecolor2.g, b: whitecolor2.b, ease: 'none', duration: 6}, "<")
    
        .to(alphaValue, {value: 0, ease: 'none', duration: 5}, "<")
    
        .to(camera.position, {x: -.9, y: 1, z: 2.3, duration: 6}, ">")
        .to(camera.rotation, {x: 0, y: .14, z: 0, duration: 6}, "<")
         
        .to(camera.position, {x: -0.07, y:1.6, z: 13, duration: 8}, ">1")////////////////////////
        .to(camera.rotation, {x: 0, y: 0, z: 0, duration: 8}, "<")
    
        //close windows
        .to(windowleftMesh.rotation, {y: 0.5, ease: 'none', duration: 2.2}, "<1.5")
        .to(windowrightMesh.rotation, {y: -0.9, ease: 'none', duration: 2.48}, "<")
    
        //show painting wave
        .to(progressWave, {value: 0, ease: 'none', duration: 7}, "<")
        .to(circleMat, {opacity: 0, ease: 'none', duration: 1.5}, "<2")
        .to(godRays, {samples: 0, ease: 'none', duration: 2.5}, "<")
        .to(bloomTable, {intensity: 0, ease: 'none', duration: 3}, "<")
    
        //dilemma
        .to(camera.position, {x: 0.5, y:1.014, z: 11.96, duration: 4}, ">5.5")////////////////////////////////
    
        .to(camera.rotation, {x: 0, y: 1.6, z: 0, duration: 4}, "<")

        //dilemma text
        .to(redlinemesh.scale, {x: -5.4, duration: 16}, "<")
    
        .to(contentedilemma.material, {opacity: 0, duration: 6}, "<4")
        //.to(contentedilemma2.material, {opacity: 1, duration: 2}, ">")
        //.to(contentedilemma2.material, {opacity: 0, duration: 2}, ">")
    
        .to(brogreessRedColor1, {r: brogreessGreenColor1.r, g: brogreessGreenColor1.g, b: brogreessGreenColor1.b, ease: 'none', duration: 2.5}, ">-1")
       
        .to(metaversedilemma, {fillOpacity: 0, duration: 2}, "<-1")
        .to(opportunity, {fillOpacity: 1, duration: 2}, ">-0.5")
      
        //opportunity text
        .to(text3.material, {opacity: 1, duration: 6}, ">-1")
        //.to(text3.material, {opacity: 0, duration: 4}, ">")
        //.to(text4.material, {opacity: 1, duration: 2}, ">")
    
        //next panal: services
        .to(camera.position, {x: 0.5, y: 1.014, z: 13.815, duration: 4}, ">1")
    
        //last panal
        .to(camera.position, {x: 1, y:1.014, z: 16.8, duration: 4}, ">4")
        .set(coverPlane, {visible: true}, ">")

        .to(camera.position, {x: -0.6, y:0.58, z: 25, duration: 5}, ">2")
        .to(camera.rotation, {x: 0, y: 0, z: 0, duration: 4}, "<")
        
        .to(camera.position, {x: -0.6, y:0.58, z: 40, duration: 4}, ">1")
    
        .to(camera.position, {x: -14.93, y: 0.58, z: 31.39, duration: 4}, ">")
        .to(camera.rotation, {x: 0, y: 0, z: 0, duration: 4}, "<")

        .to(camera.position, {x: -16.558, y: 2.409, z: 7, duration: 5}, ">")

        .to(backlightmat, {opacity: 1, duration: 5}, "<-3")
        
        .to(smokemat, {opacity: .8, duration: 5}, "<1")
        .to(sparklemat, {opacity: 0.5, duration: 5}, "<")
        //.to(".card", {opacity: 0, duration: 0.5}, "<")

        .to(citymanmat.color, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 4}, "<2")
        .to(citymat.color, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 4}, "<1")

        //.set(".contact-container", {visibility: "visible"}, "<")
        .fromTo(".contactus", {autoAlpha: 1}, {autoAlpha: 0, duration: 0.5}, "<")
        .fromTo(".contact-container", {autoAlpha: 0}, {autoAlpha: 1, duration: 1}, "<0.1");

    },

    // rest portrait
    "(min-device-width: 477px) and (orientation: portrait)": function() {
      let tl = gsap.timeline({
        scrollTrigger: {
          scrub: 1,
          trigger: scrollcontainer,
          start: 0,
          end: scrollcontainerelm.offsetHeight,
          //scroller: "body"
        },
        defaults: {
          ease: "none"
        }
      });
      tl
        .to(windowleftMesh.rotation, {y: -2.2, ease: 'none', duration: 7.2})
        .to(windowrightMesh.rotation, {y: 2, ease: 'none', duration: 8}, "<")
        .to(blackcolor, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 5}, "<")
        .to(blackcolorPaintings, {r: whitecolor2.r, g: whitecolor2.g, b: whitecolor2.b, ease: 'none', duration: 5}, "<")
    
        .to(alphaValue, {value: 0.0, ease: 'none', duration: 6}, "<")
        .to(vlogo, {top: "90%", duration: 128}, '<')
        .to(verselogo, {opacity: 0, x: 70, duration: 4}, '<')    
        .to(tr, {opacity: 0, x: 500, duration: 4}, '<1')
    
        .to(title, {opacity: 0, x: -100, stagger: 0.3, duration: 4}, '<')
        .to(".scrollbutton", {bottom: -120, opacity: 0, duration: 3}, "<")
        .to(camera.position, {x: -0.57, y: 1.665, z: 1.665, duration: 5}, ">")
        .to(camera.rotation, {x: -0.503, y: 0.581, z: 0.364, duration: 5}, "<")
    
        .to(".vincent span", {duration: 3, x: 0, filter: "blur(0px)", opacity: 1, stagger: 0.6}, ">-1")
        .to(".vincent span", {duration: 2, y: 100, filter: "blur(8px)", opacity: 0, stagger: 0.3}, ">1")
    
        .to(camera.position, {x: 0.147, y: 4.699, z: 2.532, duration: 4}, ">-0.5")
        .to(camera.rotation, {x: -1.153, y: 0, z: 0, duration: 4}, "<")
    
        .to(introTextChars, {y: 0, opacity: 1, stagger: 0.3, duration: 2}, ">")
        .to(introTextChars, {x:20, stagger: 0.6, opacity: 0, duration: 2}, ">0.5")
       
        //close windows
        .to(windowleftMesh.rotation, {y: 0.5, ease: 'none', duration: 6.2}, ">-2")
        .to(windowrightMesh.rotation, {y: -0.9, ease: 'none', duration: 7}, "<")
    
        .to(camera.position, {x: -3, y: 1.35, z: .8, duration: 7}, "<2")
        .to(camera.rotation, {x: 0, y: -1.6, z: 0, duration: 7}, "<")
    
        .to(blackcolor, {r: graycolor.r, g: graycolor.g, b: graycolor.b, ease: 'none', duration: 6}, "<1")
        .to(blackcolorPaintings, {r: blackcolorPaintings.r, g: blackcolorPaintings.g, b: blackcolorPaintings.b, ease: 'none', duration: 6}, "<")
        .to(alphaValue, {value: 1, ease: 'none', duration: 5}, "<2")
    
        //text we create
        .to(wecreate, {strokeOpacity: 1, duration: 3}, "<3.7")
        .to(wecreate, {fillOpacity: 1, duration: 2}, ">-2")
    
        .to(wecreate, {fillOpacity: 0, duration: 2}, ">")
        .to(wecreate, {strokeOpacity: 0, duration: 3}, ">-2")
    
        //open windows
        .to(windowleftMesh.rotation, {y: -2.2, ease: 'none', duration: 6.2}, ">-2")
        .to(windowrightMesh.rotation, {y: 2, ease: 'none', duration: 7}, "<")
        .to(blackcolor, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 6}, "<2")
        .to(blackcolorPaintings, {r: whitecolor2.r, g: whitecolor2.g, b: whitecolor2.b, ease: 'none', duration: 6}, "<")
    
        .to(alphaValue, {value: 0, ease: 'none', duration: 5}, "<")
    
        .to(camera.position, {x: -.9, y: 1, z: 2.3, duration: 6}, ">")
        .to(camera.rotation, {x: 0, y: .14, z: 0, duration: 6}, "<")
    
        
        .to(camera.position, {x: -0.07, y:1.6, z: 13, duration: 8}, ">1")
        .to(camera.rotation, {x: 0, y: 0, z: 0, duration: 8}, "<")
    
        //close windows
        .to(windowleftMesh.rotation, {y: 0.5, ease: 'none', duration: 2.2}, "<1.5")
        .to(windowrightMesh.rotation, {y: -0.9, ease: 'none', duration: 2.48}, "<")
    
        //show painting wave
        .to(progressWave, {value: 0, ease: 'none', duration: 7}, "<")
        .to(circleMat, {opacity: 0, ease: 'none', duration: 1.5}, "<2")
        .to(godRays, {samples: 0, ease: 'none', duration: 2.5}, "<")
        .to(bloomTable, {intensity: 0, ease: 'none', duration: 3}, "<")
    
        //dilemma
        .to(camera.position, {x: 0.5, y:1.014, z: 11.96, duration: 4}, ">5.5")
    
        .to(camera.rotation, {x: 0, y: 1.6, z: 0, duration: 4}, "<")
    
        //dilemma text
        .to(redlinemesh.scale, {x: -5.4, duration: 16}, "<")
    
        .to(contentedilemma.material, {opacity: 0, duration: 6}, "<4")
        //.to(contentedilemma2.material, {opacity: 1, duration: 2}, ">")
        //.to(contentedilemma2.material, {opacity: 0, duration: 2}, ">")
    
       .to(brogreessRedColor1, {r: brogreessGreenColor1.r, g: brogreessGreenColor1.g, b: brogreessGreenColor1.b, ease: 'none', duration: 2.5}, ">-1")
       
        .to(metaversedilemma, {fillOpacity: 0, duration: 2}, "<-1")
        .to(opportunity, {fillOpacity: 1, duration: 2}, ">-0.5")
      
        //opportunity text
        .to(text3.material, {opacity: 1, duration: 6}, ">-1")
        //.to(text3.material, {opacity: 0, duration: 2}, ">")
        //.to(text4.material, {opacity: 1, duration: 2}, ">")
    
        //next panal: services
        .to(camera.position, {x: 0.5, y:1.014, z: 13.815, duration: 4}, ">1")
    
        //last panal
        .to(camera.position, {x: 1, y:1.014, z: 16.8, duration: 4}, ">4")
        .set(coverPlane, {visible: true}, ">")

        .to(camera.position, {x: -0.6, y:0.58, z: 25, duration: 5}, ">2")
        .to(camera.rotation, {x: 0, y: 0, z: 0, duration: 4}, "<")
        
        .to(camera.position, {x: -0.6, y:0.58, z: 40, duration: 4}, ">1")
    
        .to(camera.position, {x: -14.93, y: 0.58, z: 31.39, duration: 4}, ">")
        .to(camera.rotation, {x: 0, y: 0, z: 0, duration: 4}, "<")

        .to(camera.position, {x: -16.558, y: 2.409, z: 7, duration: 4}, ">")

        .to(backlightmat, {opacity: 1, duration: 5}, "<-3")
        .to(smokemat, {opacity: .8, duration: 5}, "<1")
        .to(sparklemat, {opacity: 0.5, duration: 5}, "<")
        .to(citymanmat.color, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 4}, "<2")
        .to(citymat.color, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 4}, "<1")

        .fromTo(".contactus", {autoAlpha: 1}, {autoAlpha: 0, duration: 0.5}, "<")
        .fromTo(".contact-container", {autoAlpha: 0}, {autoAlpha: 1, duration: 1}, "<0.1");
    },

    // mobile and taplet and laptop (all) landscape
    "(orientation: landscape)": function() {
      let tl = gsap.timeline({
        scrollTrigger: {
          scrub: 1,
          trigger: scrollcontainer,
          start: 0,
          end: scrollcontainerelm.offsetHeight,
          //onUpdate: self => console.log("progress:", self.progress)////trigger => {if (trigger.progress >= 0.8 ) {ctl2.play(); console.log(trigger.progress);}}
          //markers: true,
        },
        defaults: {
          ease: "none"
        }
      });
      tl
        .to(windowleftMesh.rotation, {y: -2.2, ease: 'none', duration: 7.2})
        .to(windowrightMesh.rotation, {y: 2, ease: 'none', duration: 8}, "<")
        .to(blackcolor, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 5}, "<")
        .to(blackcolorPaintings, {r: whitecolor2.r, g: whitecolor2.g, b: whitecolor2.b, ease: 'none', duration: 5}, "<")
    
        .to(alphaValue, {value: 0.0, ease: 'none', duration: 6}, "<")
        .to(vlogo, {top: "90%", duration: 128}, '<')
        .to(verselogo, {opacity: 0, x: 70, duration: 4}, '<')    
        .to(tr, {opacity: 0, x: 500, duration: 4}, '<1')
    
        .to(title, {opacity: 0, x: -100, stagger: 0.3, duration: 4}, '<')
        .to(".scrollbutton", {bottom: -120, opacity: 0, duration: 3}, "<")
        .to(camera.position, {x: -0.57, y: 1.665, z: 1.665, duration: 5}, ">")
        .to(camera.rotation, {x: -0.503, y: 0.581, z: 0.364, duration: 5}, "<")
    
        .to(".vincent span", {duration: 3, x: 0, filter: "blur(0px)", opacity: 1, stagger: 0.6}, ">-1")
        .to(".vincent span", {duration: 2, y: 100, filter: "blur(8px)", opacity: 0, stagger: 0.3}, ">1")
    
        .to(camera.position, {x: -0.3, y: 4.699, z: 2.532, duration: 4}, ">-0.5")
        .to(camera.rotation, {x: -1.153, y: 0, z: 0, duration: 4}, "<")
    
        .to(introTextChars, {y: 0, opacity: 1, stagger: 0.3, duration: 2}, ">")
        .to(introTextChars, {x:20, stagger: 0.6, opacity: 0, duration: 2}, ">0.5")
       
        //close windows
        .to(windowleftMesh.rotation, {y: 0.5, ease: 'none', duration: 6.2}, ">-2")
        .to(windowrightMesh.rotation, {y: -0.9, ease: 'none', duration: 7}, "<")
    
        .to(camera.position, {x: -2.7, y: 1.35, z: 0.9, duration: 7}, "<2")
        .to(camera.rotation, {x: 0, y: -1.6, z: 0, duration: 7}, "<")
    
        .to(blackcolor, {r: graycolor.r, g: graycolor.g, b: graycolor.b, ease: 'none', duration: 6}, "<1")
        .to(blackcolorPaintings, {r: blackcolorPaintings.r, g: blackcolorPaintings.g, b: blackcolorPaintings.b, ease: 'none', duration: 6}, "<")
        .to(alphaValue, {value: 1, ease: 'none', duration: 5}, "<2")
    
        //text we create
        .to(wecreate, {strokeOpacity: 1, duration: 3}, "<3.7")
        .to(wecreate, {fillOpacity: 1, duration: 2}, ">-2")
    
        .to(wecreate, {fillOpacity: 0, duration: 2}, ">")
        .to(wecreate, {strokeOpacity: 0, duration: 3}, ">-2")
    
        //open windows
        .to(windowleftMesh.rotation, {y: -2.2, ease: 'none', duration: 6.2}, ">-2")
        .to(windowrightMesh.rotation, {y: 2, ease: 'none', duration: 7}, "<")
        .to(blackcolor, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 6}, "<2")
        .to(blackcolorPaintings, {r: whitecolor2.r, g: whitecolor2.g, b: whitecolor2.b, ease: 'none', duration: 6}, "<")
    
        .to(alphaValue, {value: 0, ease: 'none', duration: 5}, "<")
    
        .to(camera.position, {x: -.9, y: 1, z: 2.3, duration: 6}, ">")
        .to(camera.rotation, {x: 0, y: .14, z: 0, duration: 6}, "<")
    
        
        .to(camera.position, {x: -0.1, y:1.4, z: 11, duration: 8}, ">1")
        .to(camera.rotation, {x: 0, y: 0, z: 0, duration: 8}, "<")
    
        //close windows
        .to(windowleftMesh.rotation, {y: 0.5, ease: 'none', duration: 2.2}, "<1.5")
        .to(windowrightMesh.rotation, {y: -0.9, ease: 'none', duration: 2.48}, "<")
    
        //show painting wave
        .to(progressWave, {value: 0, ease: 'none', duration: 7}, "<")
        .to(circleMat, {opacity: 0, ease: 'none', duration: 1.5}, "<2")
        .to(godRays, {samples: 0, ease: 'none', duration: 2.5}, "<")
        //.to(bloomTable, {intensity: 0, ease: 'easeIn', duration: 3}, "<")
    
        //dilemma
        .to(camera.position, {x: -0.2, y:1.1, z: 11.96, duration: 4}, ">5.5")
    
        .to(camera.rotation, {x: 0, y: 1.6, z: 0, duration: 4}, "<")
    
        //dilemma text
        .to(redlinemesh.scale, {x: -5.4, duration: 16}, "<")
    
        .to(contentedilemma.material, {opacity: 0, duration: 6}, "<4")
        //.to(contentedilemma2.material, {opacity: 1, duration: 2}, ">")
        //.to(contentedilemma2.material, {opacity: 0, duration: 2}, ">")
    
       .to(brogreessRedColor1, {r: brogreessGreenColor1.r, g: brogreessGreenColor1.g, b: brogreessGreenColor1.b, ease: 'none', duration: 2.5}, ">-1")
       
        .to(metaversedilemma, {fillOpacity: 0, duration: 2}, "<-1")
        .to(opportunity, {fillOpacity: 1, duration: 2}, ">-0.5")
      
        //opportunity text
        .to(text3.material, {opacity: 1, duration: 6}, ">-1")
        //.to(text3.material, {opacity: 0, duration: 2}, ">")
        //.to(text4.material, {opacity: 1, duration: 2}, ">")
    
        //next panal: services
        .to(camera.position, {x: -0.2, y: 1.1, z: 13.815, duration: 4}, ">1")
    
        //last panal
        .to(camera.position, {x: -1, y:1.014, z: 16.8, duration: 4}, ">4")
        .set(coverPlane, {visible: true}, ">")

        .to(camera.position, {x: -0.6, y:0.58, z: 25, duration: 5}, ">2")
        .to(camera.rotation, {x: 0, y: 0, z: 0, duration: 4}, "<")
        
        .to(camera.position, {x: -0.6, y:0.58, z: 40, duration: 4}, ">1")
    
        .to(camera.position, {x: -14.93, y: 0.58, z: 15, duration: 4}, ">")
        .to(camera.rotation, {x: 0, y: 0, z: 0, duration: 4}, "<")

        .to(camera.position, {x: -15, y: 0, z: -2, duration: 4}, ">")

        .to(backlightmat, {opacity: 1, duration: 5}, "<-1")
        .to(smokemat, {opacity: .8, duration: 5}, "<1")
        .to(sparklemat, {opacity: 0.5, duration: 5}, "<")
        .to(citymanmat.color, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 4}, "<1")
        .to(citymat.color, {r: whitecolor.r, g: whitecolor.g, b: whitecolor.b, ease: 'none', duration: 4}, "<1")

        .fromTo(".contactus", {autoAlpha: 1}, {autoAlpha: 0, duration: 0.5}, "<")
        .fromTo(".contact-container", {autoAlpha: 0}, {autoAlpha: 1, duration: 1}, "<0.1");

    }
  });

  
  //#endregion

};

/* manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {

	//console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );

}; */
//#endregion

/* secondmanager.onLoad = function ( ) {
}; */

//THREE.Cache.enabled = true;

//#region base rest

/**
 * Sizes
 */
 let sizes;
 let pixelLimit;
 
function resizeCanvasToDisplaySize() {

  let isMobile = window.matchMedia("(max-device-width: 476px) and (orientation: portrait) and (hover: none)").matches; 
  let isNoneHover = window.matchMedia("(hover: none)").matches; 

  //update sizes
  if (isMobile) {
    sizes = {
      width: window.innerWidth,
      height: window.screen.height
    }
    pixelLimit = 1.5
  } else if (isNoneHover) {
    sizes = {
      width: window.innerWidth,
      height: document.body.scrollHeight
    }
    pixelLimit = 1.5
  } else {
    sizes = {
      width: window.innerWidth,
      height: document.body.scrollHeight
    }
    pixelLimit = 1
  }
  
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, pixelLimit))
    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    // Update effect composer
    composer.setSize(sizes.width, sizes.height)
}

/**
 * Renderer
 */
 const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  powerPreference: "high-performance",
  antialias: false,
  //alpha: true,
  stencil: false,
  depth: false  
})
renderer.outputEncoding = THREE.sRGBEncoding
renderer.debug.checkShaderErrors = false
//renderer.autoClear = true

renderer.initTexture(bedroompaintingtex)

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, 2, 0.1, 100)
camera.position.x = -0.6
camera.position.y = 2
camera.position.z = 1.9
camera.rotation.set(0, 0, 0);
scene.add(camera)

  /* gui.add(camera.position, 'x').min(-30).max(20).step(0.001)
  gui.add(camera.position, 'y').min(-30).max(20).step(0.001)
  gui.add(camera.position, 'z').min(-30).max(50).step(0.001)
  gui.add(camera.rotation, 'x').min(-30).max(20).step(0.001)
  gui.add(camera.rotation, 'y').min(-30).max(20).step(0.001)
  gui.add(camera.rotation, 'z').min(-30).max(20).step(0.001) */
  // gui.add(camera.fov, 'z')
  // gui.add(camera, 'visible')

renderer.compile(scene, camera)
//renderer.getContext()

 // godray
 const godRays = new GodRaysEffect(camera, circle, {
    blurriness: 1,
    density: 0.91,
    decay: 0.88,
    weight: 0.4,
    exposure: 0.2,
    samples: 35,
    clampMax: 1 
    });    

// blooom
 const bloomTable = new SelectiveBloomEffect(scene, camera, {
    luminanceThreshold: 0.21,
    intensity: 2.5,
})

let renderPasss = new RenderPass(scene, camera);
let effectPass = new EffectPass(camera, godRays, bloomTable);
effectPass.renderToScreen = true;
const composer = new EffectComposer(renderer, { frameBufferType: HalfFloatType });
composer.addPass(renderPasss);
composer.addPass(effectPass);
//#endregion


/**
 * clock tick
 */
const clock = new THREE.Clock()
const tick = () =>
{
    // stats
    //stats.begin()
    resizeCanvasToDisplaySize();
    const elapsedTime = clock.getElapsedTime()

    //shader
    shad3.uniforms[ 'time' ].value = performance.now() / 1000;
    uniformsshad5[ 'time' ].value = elapsedTime / 3;
    uniformsshad5[ 'alpha' ].value = alphaValue.value;
    uniformsshad1[ 'alpha' ].value = alphaValue1.value;
    
    wavematerial.uniforms.time.value = elapsedTime 
    wavematerial.uniforms[ 'progress' ].value = progressWave.value;

    //red green shader
    uniforms3.u_time.value = elapsedTime
    redgreenshad.uniforms[ 'u_c1' ].value = brogreessRedColor1;
    
    // Render
    composer.render()
    // stats
    //stats.end()
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

    // renderer.info.reset()
}
tick()

// access debug information
/* renderer.info.reset()
renderer.info.autoReset = false
console.log(renderer.info) */
